#auth{


    background:url('../images/bg-intersection.png')!important;

    .loginlink:hover{
        text-decoration: none;
        color:#EB0C8B;
    }

    .wrap-login {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 27px 35px 33px 35px;
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    }


    .login-form-btn {
        font-size: 15px;
        color: #fff;
        background: #EB0C8B;
        text-decoration: none;
        line-height: 1.2;
        text-transform: uppercase;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        height: 50px;
    }

    .footercredits{
        font-size:13px;
        color:grey;
    }

    .footer-ftp-credits{
        text-shadow:0px 0px 3px black;
    }
}
