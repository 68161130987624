
#footer-main {
    font-size:11px;
    color:grey;
    font-weight:bold;
    
}

#footer-main a, #footer-main a:hover{
    text-decoration:none;
}