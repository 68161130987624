body{
    background: #f2f2f2;
}

.mandatoryfield{
    color:$colorpfp;
    font-weight:bold;
}

#maincard{

    .page-title-card{
        color:$colorpfp;
        font-weight:bold;
    }

    .card-header svg{
        color:#a7bdcd;
    }

}

.modal-body ol li{
    margin-bottom:5px;
}

.has-data{
    border-color:$colorpfp-dim;
}

.line-pfp{
    border-color:$colorpfp;
}

.line{
    border-color:#688199;
}

.text-pfp{
    color:$colorpfp!important;
}

.btn-pfp{
    font-size: 15px;
    color: $colorpfp;
    background: #fff;
    border:2px solid $colorpfp;
    text-decoration: none;
    line-height: 1.2;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 20px;
    height: 30px;
    transition-duration: 0.3s;
}

.btn-pfp:hover{
    color: #fff;
    background: $colorpfp;
    transition-duration: 0.5s;
}

.btn-pfp-xs{
    font-size: 12px;
    color: $colorpfp;
    background: #fff;
    border:2px solid $colorpfp;
    text-decoration: none;
    line-height: 1.2;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 20px;
    height: 20px;
    transition-duration: 0.3s;
}

.btn-pfp-xs:hover{
    color: #fff;
    background: $colorpfp;
    transition-duration: 0.5s;
}

.alert ul{
    margin-bottom:0px;
}


.menu-entity{
    box-sizing: border-box;
    border-bottom:4px solid #eb0c8b30;
    border-top:4px solid #eb0c8b30;
}


.nav-tabs {
    border-bottom: 1px solid $colorpfp;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: $colorpfp;
    border-color: #f6f6f6 #f6f6f6 $colorpfp;
    font-weight: bold;
}

.nav-tabs .nav-link:hover {
    border-bottom: 1px solid $colorpfp;
    color: $colorpfp;
    font-weight: bold;
}

.nav-tabs .nav-link.active:hover {
    color: white;
}


/**
* Select2
*
*/

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 34px!important;
    user-select: none;
    -webkit-user-select: none; }


.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 32px!important; }


.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 32px!important;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
