// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// FontAwesome is imported via js
// Fonts
@import 'fonts';
// Navbar
@import 'nav';
// Headers
@import 'headers';
// Footers
@import 'footers';
// Auth
@import 'auth';


// Vendors
@import 'bootstrap-datepicker';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";


// PFP Styles
@import 'pfp';