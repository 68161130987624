#navmain{

    background: #e6e6e6;

    .navbar-toggler-icon {
        background-image: url('../images/menu-toggler.png');
    }

    .navbar-toggler{
        border:0px;
    }

    .nav-link {
        color: #EB0C8B;
        font-weight: bold;
    }

    .navbar-nav svg{
        color:rgba(0, 0, 0, 0.45);
    }

    .nametop{
        display: block;
        margin-top: 5px;
        font-weight: bold;
        color: #3c3838;
        background: white;
        padding: 3px 15px;
        border-radius: 10px;
    }

    .numerosociotop{
        color: #EB0C8B;
        font-weight: normal;
        font-size:12px;
        font-weight: bold;
    }
}


#sidemenu {

    ul,li{
        list-style-type: none;
        margin:0px;
        padding:0px;
    }

    a{
        text-decoration:none;
    }


    .item{
        text-align:left;
        padding:10px;
        color:#a7bdcd;
    }

    .item:hover{
        color:#EB2D8B;
        background:#F9E9EA;
    }

    .item span{
       color:#678098;
    }

    .active{
        border-left:5px solid #EB2D8B;
        background:#F9E9EA;
        color:#EB2D8B;
    }

    .active span{
       color:#678098;
    }
}

#sidemenu-area{
    background:white;

}
